let googleAnalyticsMeasurementID = 'G-V17FZ55RFV';

window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

Promise.all([
    new Promise((resolve) => {
        var script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsMeasurementID;
        script.async = true;
        document.body.appendChild(script);
        resolve();
    }),
    new Promise((resolve) => {
        gtag('js', new Date());
        gtag('config', googleAnalyticsMeasurementID);
        resolve();
    })
]);
